
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          












































































.app-container {
  transition: padding 0.2s ease;
  padding-top: 45px;
  @include breakpoint('lg') {
    padding-top: 100px;
  }
}
