
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          





































































.subnavigation-page {
  padding-top: 4rem;
  padding-bottom: 4rem;
  ul,
  li {
    list-style: none;
    margin: 0;
  }
  &__wrapper {

  }
  &__nav {
    margin-bottom: 3rem !important;
    @include breakpoint("lg") {
      padding-right: 4rem;
    }
  }
  &__link-lvl1 {
      a {
        font-size: 1.8rem;
        padding-bottom: 2rem;
        margin-bottom: 2rem;
        border-bottom: 1px solid $grey-light;
        font-weight: 600;

        display: flex;
        align-items: center;
        justify-content: space-between;

        svg {
          width: 1.4rem;
        }
      }
  }
  &__sublink {
    color: $dark-grey-b2b;
    margin-bottom: 0.7rem;
    display: inline-block;
  }
}
