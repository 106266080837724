
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          






























































































































































































































































































































































































































































.my-router {
  width: 100%;
  height: 100%;
}
