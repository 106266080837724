
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          











































































































































































.multiMenu {
  &__cta {
    display: flex;
    align-items: center;
    padding: 0 2.5vw;
    cursor: pointer;

    &--text {
      color: $white;
      margin-left: 2rem;
      font-size: 1.6rem;
    }
  }

  &__wrapper {
    opacity: 0;
    transform: translateY(-10px);
    pointer-events: none;
    transition: all 0.3s ease-out;

    @include breakpoint("lg") {
      position: absolute;
      top: 100%;
      width: calc(100vw - 16.6666666667vw);
      display: flex;
    }

    &.active {
      pointer-events: initial;
      opacity: 1;
      transform: translateY(0px);
    }

    ul,
    li {
      list-style: none;
      margin: 0;
    }

    &--level1 {
      background-color: $grey-light;
      cursor: pointer;
      @include breakpoint("lg") {
        padding-top: 2.5rem;
        min-width: 350px;
      }
    }

    &--level2 {
      padding: 2rem 2.5rem 2.5rem 2.5vw;
      background-color: $grey-b2b;
      flex: 1 1 auto;
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;

      @include breakpoint("lg") {
        padding: 3.5rem 2.5rem 2.5rem 2.5vw;
        justify-content: space-between;
        position: relative;
        top: initial;
        height: auto;
        width: auto;
      }
    }

    &--lvl2-wrapper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }

    &--item-lvl1 {
      padding: 1.5rem 1.5rem 1.5rem 2.5vw;
      height: 7rem;
      margin: 0;
      font-size: 1.4rem;
      font-weight: 600;
      transition: all 0.3s ease;

      @include breakpoint("lg") {
        height: auto;
      }

      .icon {
        opacity: 0;
        transition: all 0.1s ease;
        display: none;
        @include breakpoint("lg") {
          display: block;
        }
      }

      &:hover,
      &.active {
        background-color: $dark-grey-b2b;
        color: $white;
        text-decoration: none;

        .icon {
          opacity: 1;
        }
      }
    }

    &--subtitle-lvl1 {
      display: flex;
      justify-content: center;
      align-items: center;

      @include breakpoint("lg") {
        justify-content: space-between;
      }

      svg {
        width: 1.4rem;
      }
    }

    &--big-subtitle {
      font-size: 1.8rem;
      padding-bottom: 1rem;
      margin-bottom: 2rem;
      border-bottom: 1px solid $grey-light;
      display: block;
      font-weight: 600;
      flex: 2 2 100%;
    }

    &--item-lvl1 {
      padding: 1.5rem 1.5rem 1.5rem 2.5vw;
      margin: 0;
      font-size: 1.4rem;
      font-weight: 600;

      &:hover {
        background-color: $dark-grey-b2b;
        color: $white;
      }
    }

    &--item-lvl2 {
      flex: 0 1 100%;
      padding-bottom: 3rem;

      @include breakpoint("md") {
        padding-bottom: 0;
        flex: 0 1 32%;

        &:nth-child(n + 5) {
          margin-top: 4rem;
        }
      }
    }

    &--item-lvl3 {
      font-size: 1.2rem;

      &:first-child {
        font-size: 1.4rem;
        font-weight: 600;
        margin-bottom: 1.5rem;
      }
    }

    &--back-to {
      display: block;
      margin-bottom: 2rem;
      cursor: pointer;
      font-weight: 600;
    }
  }
}

.home-icon {
  display: none;
  @media only screen and (min-width: 1025px) {
    display: inline-block;
  }
}
