
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          






































































































































.ais-search-container {
  width: 6.3333333333vw;

  .ais-Stats-text {
    display: none;
  }
  .ais-search-input {
    position: absolute;
    top: 100%;
    right: 0;
    width: 0vw;
    height: 70px;
    border: none;
    border-radius: 0;
    transition: all 0.2s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    pointer-events: none;
    padding: 0;

    @include breakpoint('lg') {
        bottom: 0;
        top: initial;
    }

    /* clears the 'X' from Internet Explorer */
    &::-ms-clear,
    &::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }

    /* clears the 'X' from Chrome */
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }
  }
  .ais-SearchBox-submit {
    color: white;
    position: relative;
    z-index: 2;
    cursor: pointer;
  }
  &__cta-search {
    color: #fff;
    position: relative;
    z-index: 2;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .active & {
      @include breakpoint('lg') {
        color: #000;
      }
    }
  }
  .ais-reset-cta {
    position: absolute;
    top: 0;
  }
  &.active {
    .ais-search-input {
      width: 100%;
      pointer-events: auto;
      padding: 20px 40px;
      font-size: 2.1rem;

      @include breakpoint('lg') {
        width: 39.666666665vw;
      }
    }
    &__cta-search {
      color: #fff;
    }
  }
  .ais-Highlight-highlighted {
    background-color: $red;
    color: $white;
  }
  .ais-Pagination {
    .ais-Pagination-list {
      list-style: none;
    }
    .ais-Pagination-item {
      &--previousPage {
      }
      &--nextPage {
      }
      .ais-Pagination-item {
      }
    }
  }
}
.ais-search-results {
  background-color: $grey-b2b;
  padding: 4rem;
  max-height: calc(100vh - 100px);
  overflow: auto;
  position: absolute;
  top: calc(100% + 70px);
  left: 0;
  width: 100vw;

  @include breakpoint('lg') {
    right: 0;
    left: initial;
    top: 100%;
    max-width: 39.666666665vw;
  }

  &__item {
    margin-bottom: 3rem;
    em {
      font-size: 2.1rem;
      font-style: normal;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
