html {
  height: 100%;
  width: 100%;
  font-size: 10px;
}

body {
  height: 100%;
  width: 100%;
  background-color: $grey-b2b;
}

#app {
  height: 100%;
}

::-moz-selection { /* Code for Firefox */
  color: $white;
  background: $red;
}

::selection {
  color: $white;
  background: $red;
}

::-ms-clear {
  display: none !important;
  width: 0px !important;
  height: 0px !important;
}

.hero_store {
  display: none;
}
.hero_shop {
  display: none;
}

.icon-base{
  background-image: url('/img/default.svg');
  background-position: top left;
  background-size: contain;
  background-repeat: no-repeat;

  img{
    background-color: #202124;
  }
}

.input-wrapper{
  margin-bottom: 2rem;
  @include breakpoint('lg') {
    margin-bottom: 3.5rem;
  }
  p.error{
      display: none;
  }
  &.__error{
      input,textarea,select{
          border-bottom: 1px solid $red;
      }
      textarea{
          border: 1px solid $red;
      }
      p.error{
          display: block;
          margin: 0;
          margin-bottom: 0.5rem!important;
      }
  }
  &:last-child{
      margin-bottom: 0;
  }
  input,select{
      border: 0;
      width: 100%;
      border-bottom: 1px solid $dark-grey-b2b;
      height: 4rem;
      outline: none;
      border-radius: none;
  }
  select{
    appearance: none;
    line-height: normal;
    position: relative;
    background-image: url('~@/assets/icons/chevron-down.svg');
    background-size: 20px;
    background-position: right top 65%;
    background-repeat: no-repeat;
    border-radius: 0px;
    background-color: transparent;
  }

  &.__double{

    @include breakpoint('lg') {
      display: flex;
      justify-content: space-between;
    }

    & > div {
      margin-bottom: 2rem;
      @include breakpoint('lg') {
        flex: 0 1 48%;
        margin-bottom: 0;
      }
    }
  }
}
p.error{
  color: $red;
  font-size: 1.2rem !important;
  font-weight: 400!important;
}

.my_child_active {
  background-color: $menu-active !important;
  color: $white !important;
}

.my_child_under_active {
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1.5rem;
    background-color: $menu-active !important;
  }
  background-color: $white !important;
  color: $black !important;
}

p:empty {
  height: 2rem;
  display: table-row;
}

.pc-logo,
.pc-footer-logo,
.powered-by-logo{
  visibility: hidden!important;
}

#onetrust-banner-sdk.otFlat{
  z-index: 99!important;
  overflow: hidden !important;
}

img{
  &.embed-asset{
    margin:20px 0;
  }
}

.container-custom {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @include breakpoint("mdpi") {
    width: 85vw;
  }
}
.fill-white {
  fill: $white !important;
}