
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          












































































































































.header_wrapper {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9;
  border-bottom: 1px solid $grey-b2b;
  height: 45px;
}
a {
  transition: all 0.3s ease-out;
  text-decoration: none;
}

.nav-fixed-container {
  height: calc(
    100vh - 45px
  ); /* Fallback for browsers that do not support Custom Properties */
  height: calc((var(--vh, 1vh) * 100) - 45px);
  width: 100%;
  position: fixed;
  left: 0;
  top: 45px;
  z-index: 99;
  transform: translateX(100%);
  transition: transform 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  overflow: scroll;

  &.__is-active {
    transform: translateX(0%);
  }
}

.header-primary {
  background-color: $dark-grey-unipro;
  height: 4.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
}

.brand-nav {
  img {
    height: 31px;
  }
}

.right-nav {
  display: flex;
  align-items: center;

  .__cta-search {
    width: 30px;
    svg path {
      fill: white;
    }
  }
}
.lang-switcher {
  color: #fff;
  margin-left: 1rem;
}
.nav-mobile-wrapper {
  background-color: $grey-b2b;

  label {
    margin: 0;
  }

  & a {
    height: 7rem;
    display: flex;
    font-weight: 600;

    &.router-link-active {
      background-color: $menu-active;
    }
  }

  .store-nav {
    background-color: $grey-light;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 2rem;
    position: relative;

    label {
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 0;
      text-align: center;
    }

    a {
      display: flex;
      text-decoration: none;
      justify-content: center;
      align-items: center;
      padding: 0 2.5rem;
      background-color: #fff;
      border-bottom: 1px solid $grey-b2b;
      position: relative;
      > * {
        cursor: pointer;
      }
    }
    .cta-my-strore {
      address {
        font-size: 0.9rem;
        margin-bottom: 0;
        line-height: 1.22;
        font-weight: 600;
      }
    }

    .icon-group {
      position: absolute;
      right: 2.5rem;
      top: 50%;
      transform: translateY(-50%);

      svg {
        margin-right: 0.8rem;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .primary-nav {
    & > a,
    & > span a {
      color: $white;
      background-color: $black;
      font-weight: 600;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid $dark-grey-b2b;

      label {
        pointer-events: none;
      }

      &.router-link-active {
        background-color: $menu-active;
      }
    }
  }

  .nav-level-2 {
    background-color: $white;

    & a:not(.cta-back-to-menu) {
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid $grey-light;

      svg {
        position: absolute;
        right: 1.5rem;
        path {
          fill: $red;
        }
      }
    }
  }
}

.__actif {
  background-color: $red;
  color: white;
}
.cta-back-to-menu {
  background-color: $black;
  color: $white;
  justify-content: center;
  align-items: center;

  label {
    margin: 0 0 0 1.5rem;
  }
}
.cta-all {
  background-color: $red;
  color: white;
  border-bottom: 1px solid $grey-b2b !important;

  svg path {
    fill: currentColor !important;
  }
}

.search-container {
  transform: translateX(100%);
  transition: all 0.3s ease;
  pointer-events: none;
  border-bottom: 1px solid $grey-light;

  input {
    width: 100vw;
    height: 70px;
    padding: 0 3.5rem;
    background-color: $grey-b2b;
    border: none;
    transition: all 0.3s ease;
    border-radius: 0;
  }

  .input-reset {
    position: absolute;
    right: 4.5rem;
    top: calc(50% - 10px);
  }

  &.active {
    pointer-events: auto;
    transform: translateX(0%);
  }
}
.search-results {
  max-height: calc(
    100vh - 100px
  ) !important; /* Fallback for browsers that do not support Custom Properties */
  max-height: calc((var(--vh, 1vh) * 100) - 100px) !important;

  .coverflow {
    padding-bottom: 40px;
    overflow: auto;
  }
}
