
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          









































































.breadcrumb-header {
  background-color: $grey-b2b;
  background-repeat: no-repeat;
  background-position:  right bottom;
  background-size: 50%;

  @include breakpoint("lg") {
    background-size: contain;
    background-position:  83.3333333333% bottom;
  }

  &__nav {
    margin-bottom: 3rem;
    ul {
      list-style: none;
      margin: 0;
      @include breakpoint("md") {
        display: flex;
      }
    }
    li {
      margin-right: 2.5rem;
      font-weight: 600;
      &:first-child {
        a {
          color: $menu-active;
        }
        &::first-letter {
          text-transform: uppercase;
        }
      }
      .icon {
        width: 0.8rem;
        display: inline-block;
        margin-left: 2rem;
        svg {
          width: 0.8rem;
        }
      }
    }
  }
  &__content {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  &__title {
    font-size: 4rem;
  }
}
