
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          






























































































































































































































































































































































































.breadcrumb-nav {
  border-bottom: 1px solid $grey-light;
  background-color: white;

  position: fixed;
  top: 45px;
  left: 0;
  z-index: 8;
  width: 100vw;

  @include breakpoint('lg'){
    top: 100px;
    left: 0;
  }

  &__wrapper{
    height: 0;
    overflow: auto;
    transition: all 0.3s ease;

    @include breakpoint('lg') {
      height: auto;
      overflow: visible;
    }
  }
  .sub-nav-toggler{
    height: 7rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 1.6rem;
    text-transform: uppercase;
    position: relative;
    padding: 0 5rem 0 3.5rem;

    @include breakpoint('lg') {
      display: none;
    }

    .sort-cta{
      position: absolute;
      right: 2.5rem;
      top: 50%;
      transform: translateY(-50%);

      svg{
        display: block;
        &.rotated{
          transform: rotate(180deg)
        }
      }
    }
  }
  .nav-opened{
    height: calc(100vh - 116px);
    overflow: auto;

    @include breakpoint('lg') {
      height: initial;
    }
  }
  .breadcrumb-nav-item{
      width: 100vw;
      flex: 0 1 100%;
      height: 7rem;

      border: 1px solid $grey-light;
      border-width: 1px 1px 0px 0px;
      position: relative;
      &:first-child{
        border-width: 1px 1px 0px 1px;
      }

      font-size: 1.3rem;
      display: inline-flex;
      justify-content: center;
      text-decoration: none;
      cursor: pointer!important;

      @include breakpoint('lg') {
        text-align: center;
        width: 8rem;
        flex: 0 1 8rem;
        height: 8rem;
        flex-direction: column;
        font-size: 1rem;
      }

      div,label{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        cursor: pointer!important;
      }

      > div{
        position: absolute;
        right: 2rem;
        top: 50%;
        transform: translateY(-50%);
        opacity:0.5;

        @include breakpoint('lg') {
          position: static;
          transform: none;
          right: initial;
          margin-right: 0;
          justify-content: center;
          flex: 0 1 50%;
          padding-top:0.5rem;
        }
      }

      label{
        align-items: center;
        line-height: 1.1;
        font-weight: 600;
        flex: 0 1 70%;
        justify-content:center;
        text-align: center;

        @include breakpoint(lg){
          align-items: flex-start;
          font-weight: 400;
          flex: 0 1 50%;
          padding: 0.5rem 3px 3px;
        }
      }

      &.__all{
        background-color: $dark-grey-b2b;
        border-color: $dark-grey-b2b;
        color: $white;
      }

      &.__active,
      &:hover{
        background-color: $menu-active;
        color: $white;
      }
  }
}
